export default {
    Blvt: require('./blvt').default,
    Bswap: require('./bswap').default,
    SubAccount: require('./subAccount').default,
    Market: require('./market').default,
    Trade: require('./trade').default,
    Wallet: require('./wallet').default,
    Margin: require('./margin').default,
    Mining: require('./mining').default,
    Savings: require('./savings').default,
    Staking: require('./staking').default,
    Stream: require('./stream').default,
    Websocket: require('./websocket').default,
    Futures: require('./futures').default,
    Fiat: require('./fiat').default,
    C2C: require('./c2c').default,
    Loan: require('./loan').default,
    Pay: require('./pay').default,
    Convert: require('./convert').default,
    Rebate: require('./rebate').default,
    NFT: require('./nft').default,
    GiftCard: require('./giftCard').default,
    PortfolioMargin: require('./portfolioMargin').default,
}
