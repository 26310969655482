var inherits = require('inherits')
var Buffer = require('safe-buffer').Buffer

var Base = require('cipher-base')

var ZEROS = Buffer.alloc(128)
var blocksize = 64

function Hmac(alg, key) {
    Base.call(this, 'digest')
    if (typeof key === 'string') {
        key = Buffer.from(key)
    }

    this._alg = alg
    this._key = key

    if (key.length > blocksize) {
        key = alg(key)
    } else if (key.length < blocksize) {
        key = Buffer.concat([key, ZEROS], blocksize)
    }

    var ipad = this._ipad = Buffer.allocUnsafe(blocksize)
    var opad = this._opad = Buffer.allocUnsafe(blocksize)

    for (var i = 0; i < blocksize; i++) {
        ipad[i] = key[i] ^ 0x36
        opad[i] = key[i] ^ 0x5C
    }

    this._hash = [ipad]
}

inherits(Hmac, Base)

Hmac.prototype._update = function (data) {
    this._hash.push(data)
}

Hmac.prototype._final = function () {
    var h = this._alg(Buffer.concat(this._hash))
    return this._alg(Buffer.concat([this._opad, h]))
}
export default Hmac
