Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = void 0;

var _isomorphicWs = _interopRequireDefault(require("isomorphic-ws"));

var _reconnectingWebsocket = _interopRequireDefault(require("reconnecting-websocket"));

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {default: obj};
}

function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function (sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}

function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i] != null ? arguments[i] : {};
        if (i % 2) {
            ownKeys(Object(source), true).forEach(function (key) {
                _defineProperty(target, key, source[key]);
            });
        } else if (Object.getOwnPropertyDescriptors) {
            Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
        } else {
            ownKeys(Object(source)).forEach(function (key) {
                Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
            });
        }
    }
    return target;
}

function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {value: value, enumerable: true, configurable: true, writable: true});
    } else {
        obj[key] = value;
    }
    return obj;
}

var _default = function _default(url, opts) {
    var rws = new _reconnectingWebsocket.default(url, [], _objectSpread({
        WebSocket: _isomorphicWs.default,
        connectionTimeout: 4e3,
        debug: false,
        maxReconnectionDelay: 10e3,
        maxRetries: Infinity,
        minReconnectionDelay: 4e3
    }, opts)); // TODO Maybe we have to pass the proxy to this line
    // https://github.com/pladaria/reconnecting-websocket/blob/05a2f7cb0e31f15dff5ff35ad53d07b1bec5e197/reconnecting-websocket.ts#L383

    var pong = function pong() {
        return rws._ws.pong(function () {
            return null;
        });
    };

    rws.addEventListener('open', function () {
        // .on only works in node env, not in browser. https://github.com/Ashlar/binance-api-node/issues/404#issuecomment-833668033
        if (rws._ws.on) {
            rws._ws.on('ping', pong);
        }
    });
    return rws;
};

exports.default = _default;
